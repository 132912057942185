<template>
    <main class="main-content max-height-vh-100 h-100 border-radius-lg ">
        <div class="container-fluid py-4">
            <div class="row">
                <div class=" mb-3">
                    <div class="card z-index-2 bg-transparent shadow-xl">
                        <div class="card-body ">
                            <div class="row align-items-center justify-content-center justify-content-md-start flex-row-reverse flex-md-row">
                                <div class="col-auto ">
                                    <lottie-player autoplay
                                                   loop
                                                   style="height: 200px; width: 200px"
                                                   mode="normal"
                                                   :src="`./lottie/${status}.json`"></lottie-player>
                                </div>
                                <div class="col-12 col-md-auto">
                                    <div>
                                        <h6 class="mb-0 mt-4 ">
                                            Payment was {{status == 'success' ? 'Successful' : 'Unsuccessful'}}
                                        </h6>
                                        <p class="text-sm" v-if="status == 'success'">Allocation might take a few seconds, Please be patient.</p>
                                        <p class="text-sm " v-else>Not sure what happened, please try again or contact support.</p>
                                    </div>
                                    <router-link to="/" class="btn bg-gradient-primary mb-0 mt-3 mb-4"><i class="material-icons text-sm">home</i>&nbsp;&nbsp;Home</router-link>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <footer class="footer py-4">
                <div class="container-fluid">
                    <div class="row align-items-center justify-content-lg-between">
                        <div class="cal-lg-6 mb-lg-0 mb-4">
                            <div class="copyright text-center text-sm text-muted taxt-lg-start">
                                <!--<button v-on:click="signalrtest">clicking me</button>-->
                                ⚡ By
                                <a href="https://www.wizebooks.co.za" class="font-weight-bold" target="_blank">Wize Books</a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    </main>
</template>

<script>
    import { mapState } from 'vuex'
    export default {
        data: function () {
            return {
                loading: false,
            };
        },
        props: {
            status: String
        },
        methods: {
        },
        components: {
        },
        mounted: function () {
            setTimeout(() => {
                this.$router.push('/')
            }, 15000);
        },
        computed: {
            ...mapState(['auth'])
        }
    }
</script>
<style>
    .truncate {
        width: 75vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .text--error {
        color: #F44336 !important;
    }

    .text--success {
        color: #4CAF50 !important;
    }
</style>